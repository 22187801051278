import { Link } from 'react-router-dom';
import BirdBuddyLogo from 'assets/images/BirdBuddy_logo.svg';
import FacebookIcon from 'assets/icons/fb_icon.svg';
import TwitterIcon from 'assets/icons/twitter_icon.svg';
import InstagramIcon from 'assets/icons/insta_icon.svg';
import TikTokIcon from 'assets/icons/tiktok.svg';
import LinkedinIcon from 'assets/icons/logo-linkedin.svg';
import RedditIcon from 'assets/icons/logo-reddit.svg';

const Footer = () => {
  const date = new Date();
  return (
    <footer className="footer">
      <div className="container footer__content">
        <div className="footer__column">
          <Link to="/">
            <img src={BirdBuddyLogo} alt="BB logo" />
          </Link>
        </div>
        <div className="footer__column">
          <p className="footer__column__paragraph">
            © {date.getFullYear()} Bird Buddy Inc. | 229 E. Michigan Ave., Ste.
            330 Kalamazoo, Michigan 49007
          </p>
        </div>
        <div className="footer__column social-links">
          <a href="https://www.facebook.com/mybirdbuddy/">
            <img src={FacebookIcon} alt="facebook icon" />
          </a>
          <a href="https://www.instagram.com/my.bird.buddy/">
            <img src={InstagramIcon} alt="instagram icon" />
          </a>
          <a href="https://twitter.com/mybirdbuddy">
            <img src={TwitterIcon} alt="twitter icon" />
          </a>
          <a href="https://www.tiktok.com/@mybirdbuddy">
            <img src={TikTokIcon} alt="tiktok icon" />
          </a>
          <a href="https://www.reddit.com/r/bestofbirdbuddy/">
            <img src={RedditIcon} alt="reddit icon" />
          </a>
          <a href="https://www.linkedin.com/company/birdbuddy/">
            <img src={LinkedinIcon} alt="linkedin icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
