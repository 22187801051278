import { useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon
} from 'react-share';
import Button, { ButtonCustomClasses } from './Button';
import Confetti from 'react-confetti';
// import InstagramColoredIcon from 'assets/icons/instagram_icon_colored.svg';
// import TikTokColoredIcon from 'assets/icons/tikTok_icon_colored.svg';

type Props = {
  memeUrl: string;
  downloadImage: () => void;
};

const hashtag = 'memebuddy';

const DownloadAndShare = ({ memeUrl, downloadImage }: Props) => {
  const onMakeAnotherClick = useCallback(() => {
    window.location.reload();
  }, []);

  const notify = () => {
    toast.info('Copied!');
  };

  return (
    <div className="download-and-share">
      <Confetti
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={false}
        wind={0}
        numberOfPieces={1000}
        tweenDuration={6000}
      />
      <div className="options-group">
        <p>Share on social media!</p>
        <div className="social-media-icons">
          <FacebookShareButton
            url={memeUrl}
            hashtag={`#${hashtag}`}
            className="me-4"
          >
            <FacebookIcon round size={40} />
          </FacebookShareButton>
          {/* <a href="#">
            <img src={InstagramColoredIcon} className="me-4" />
          </a> */}
          <LinkedinShareButton url={memeUrl} className="me-4">
            <LinkedinIcon round size={40} />
          </LinkedinShareButton>
          <RedditShareButton url={memeUrl} className="me-4">
            <RedditIcon round size={40} />
          </RedditShareButton>
          {/* <a href="#">
            <img src={TikTokColoredIcon} className="me-4" />
          </a> */}
          <TwitterShareButton
            url={memeUrl}
            hashtags={[hashtag]}
            className="me-4"
          >
            <TwitterIcon round size={40} />
          </TwitterShareButton>
        </div>
      </div>
      <div className="options-group">
        <p>Copy link</p>
        <div className="copy-input">
          <span>{memeUrl}</span>
          <button
            onClick={() => {
              navigator.clipboard.writeText(memeUrl);
              notify();
            }}
          >
            Copy
          </button>
        </div>
      </div>
      <div className="options-group">
        <p>Image HTML</p>
        <div
          className="copy-input"
          onClick={() => {
            navigator.clipboard.writeText(`<img src="${memeUrl}"/>`);
            notify();
          }}
        >
          <span>{`<img src="${memeUrl}"/>`}</span>
          <button>Copy</button>
        </div>
      </div>
      <div className="w-100 buttons">
        <Button
          customClass={`me-3 ${ButtonCustomClasses.GREEN}`}
          title="Download"
          onClick={downloadImage}
        />
        <Button
          title="Make another"
          customClass={ButtonCustomClasses.BORDERED}
          onClick={onMakeAnotherClick}
        />
      </div>
    </div>
  );
};

export default DownloadAndShare;
